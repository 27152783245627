.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-image: url('./images/big-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 500px;
  padding: 32px 0 24px;
}

.left-box {
  width: 380px;
  padding: 8px 0;
}

.left-box .left-bg {
  height: 100%;
  background-image: url('./images/l_bg.png');
  background-size: 100% 100%;
}

.right-box {
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
}

.right-box .ant-pro-form-login-title {
  font-size: 26px;
}

@media (min-width: 768px) {
  .ant-pro-form-login-container {
    padding: 32px 0 24px;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
}
.ant-pro-form-login-container {
  height: auto;
}